import { MenuItem, Select } from '@material-ui/core'
import React, { useState } from 'react'
import { RESET_ERROR, RESET_PASSWORD } from '../services/users-actions'
import {
  RESET_PASSWORD_LABELS,
  USERS_ACTIONS_LABELS,
} from '../services/users-constants'

import toString from 'lodash/toString'
import { useDispatch } from 'react-redux'
import { UserProfile } from 'types/userTypes'
import { useAppSelector } from 'utils/reduxHelpers'
import ConfirmModal from '../../../components/_shared/confirmModal/ConfirmModal'
import { checkConnection } from '../../../services/root/root-service'

type ActionsDropdownProps = {
  user: UserProfile
  goToCustomer: (id: number, email: string) => void
  handleOpenSnackBar: (text: string) => void
}

const ActionsDropdown = ({
  user,
  goToCustomer,
  handleOpenSnackBar,
}: ActionsDropdownProps) => {
  const [openReset, setOpenReset] = useState(false)

  const dispatch = useDispatch()

  const error = useAppSelector((state) => state.usersReducer.error)

  const resetPassword = (id: number, onSuccess: () => void) =>
    dispatch(checkConnection(() => RESET_PASSWORD(id, onSuccess)))
  const resetError = () => dispatch(RESET_ERROR())

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    const { value, name } = event.target

    switch (value) {
      case USERS_ACTIONS_LABELS.goToCustomer:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        goToCustomer(Number(name!), user.email)
        break
      case USERS_ACTIONS_LABELS.resetPassword:
        resetError()
        setOpenReset(true)
        break
      default:
        return
    }
  }

  const closeModal = () => {
    setOpenReset(false)
  }

  const onSuccess = () => {
    handleOpenSnackBar(RESET_PASSWORD_LABELS.success)

    closeModal()
  }

  return (
    <>
      <Select
        name={toString(user.id)}
        displayEmpty
        renderValue={() => USERS_ACTIONS_LABELS.selectAction}
        onChange={handleChange}
        value=""
        id="select-action-dropdown"
        fullWidth
      >
        <MenuItem value={USERS_ACTIONS_LABELS.goToCustomer}>
          {USERS_ACTIONS_LABELS.goToCustomer}
        </MenuItem>
        {user.organizationActive && (
          <MenuItem value={USERS_ACTIONS_LABELS.resetPassword}>
            {USERS_ACTIONS_LABELS.resetPassword}
          </MenuItem>
        )}
      </Select>
      <ConfirmModal
        isOpen={openReset}
        onClose={closeModal}
        onSubmit={() => resetPassword(user.id, onSuccess)}
        modalTitle={RESET_PASSWORD_LABELS.resetPassword}
        confirmText={RESET_PASSWORD_LABELS.resetPasswordText(user.id)}
        actionLabel={RESET_PASSWORD_LABELS.resetPassword}
        closeLabel={RESET_PASSWORD_LABELS.cancel}
        error={error}
      />
    </>
  )
}

export default ActionsDropdown
